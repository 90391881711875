import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Header() {

    const [menu, setMenu] = useState(false);


    const toggleMenu = () =>{
        setMenu(!menu)
    }

    return (
        <header class="p-l-r">
            <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid">
                    <Link class="navbar-brand" to="/">
                        <div class="logo-box">
                            <img src={require('../assets/images/logo.png')} alt="thebeyondlifestyle" />
                        </div>
                    </Link>
                    <button class="navbar-toggler" onClick={toggleMenu} type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class={menu ? 'collapse navbar-collapse show':'collapse navbar-collapse'} id="navbarSupportedContent">
                        <ul class="navbar-nav mb-2 mb-lg-0">

                            {/* <li class="nav-item app_link">
                                <a class="nav-link" href="https://thebeyondlifestyle.com/app/" target="_blank">Beyond Wellness</a>
                            </li> */}
                            <li class="nav-item">
                                <a class="nav-link" href="/#about">About</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="/#faq">FAQ’s</a>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to="/support">Support</Link>
                            </li>
                            {/* <li class="nav-item">

                                <a class="nav-link" target="_blank" href="https://thebeyondlifestyle.com/app/">Login / Signup</a>

                            </li> */}
                        </ul>
                        <div class="social-media">
                            <ul>
                                <li><a href="https://www.facebook.com/TheBeyondLifestyleApp" target="_blank"><i
                                    class="fa-brands fa-facebook-f"></i></a></li>
                                <li><a href="https://twitter.com/LifestyleApp24" target="_blank"><i
                                    class="fa-brands fa-x-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/the_beyond_lifestyle/" target="_blank"><i
                                    class="fa-brands fa-instagram"></i></a></li>
                                <li><a href="https://www.youtube.com/@TheBeyondLifestyle" target="_blank"><i
                                    class="fa-brands fa-youtube"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/the-beyond-lifestyle/" target="_blank"><i
                                    class="fa-brands fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}
